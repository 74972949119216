@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Work Sans", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  margin: 0 auto;
}

html {
  background-color: #060606;
}

/* ------------------------------------------------------ */
/* -- Navigation -- */

header {
} 

.about,
.projects,
.experience,
.social,
.footer {
  padding: 50px 0px 0px;
}

.about,
.projects,
.experience,
.social,
.footer {
  padding-right: 40px;
  padding-left: 40px;
}

p, h1, h2 {
  color: white;
  font-size: xx-large;
}

.buttonhead p {
  color:white;
}

/* ------------------------------------------------------ */
/* -- Header -- */

.header {
  margin: 0 auto;
}

.header__content {
  font-size: xx-large;
}

.header__content h1 {
  font-size: 100px;
  text-align: center;
  margin: 0 auto;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.header__content {
  width: 1000px;
  height: 700px;
  margin: 0 auto;
  align-items: center;
  text-align: center;
}

.header__content h1 {
  font-size: 75px;
  max-width: 500px;
  max-height: 500px;
  margin-right: -20em;
}

.headerimgcontainer {
  display: flex;
  flex-direction: column;
  width: 12%;
  margin: 0 auto;
}

.headerimgcontainer {
  margin-top: 2em;
}

.header__content p {
  display: flex;
  flex-direction: column;
  font-size: 50px;
  font-weight: bold;
  align-items: center;
  margin-top: 2em;
}

.header__content button {
  margin: 25px auto 0;
}

.header__content .buttonhead {
  width: 40%;
  height: 60%;
  font-variant: small-caps;
  border-radius: 10%;
  border: solid black 5px;
  align-items: center;
  background-color: #ffa600;
}

.header__content .buttonhead:hover {
  transition: all 500ms ease;
  width: 40%;
  height: 60%;
  font-variant: small-caps;
  border-radius: 10%;
  background-color:#ffc800;
  border: solid #ffc800 5px;
}

.buttonhead p {
  margin: 0;
}

.buttonhead .phone {
  color:#000000;
}

.reviews-container {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  margin-top: 15em;
  background-color: #fff;
  border: transparent;
  border-radius: 50px;
}

.reviews-container h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #000000;
}

.review-slideshow {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.review-card {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
  text-align: left;
}

.review-profile-img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 15px;
}

.review-content {
  flex: 1;
}

.review-content h3 {
  margin: 0;
  font-size: 1.2rem;
}

.reviewsheader .review-rating {
  margin: 0;
}

.reviewsheader h2 {
  margin: 0;
}

.reviewsheader img {
  height: 10%;
  width: 10%;
  margin-bottom: 1em;
}

.review-rating {
  margin: 5px 0;
  color: #f39c12;
  font-size: 1.1rem;
}

.review-content p {
  margin: 0;
  font-size: 1rem;
  color: #555;
}

.nav-button {
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  color: #06144b;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.nav-button:hover {
}

.alert {
  background: #222D41;
  height: 100px;
  width: 300px;
  position: fixed;
  bottom: 1.50em;
  right: 1.50em;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  transition: all 500ms ease;
}

.wrapper .alert--messages {
  background: #222D41;
  height: 600px;
  width: 300px;
  position: fixed;
  bottom: 1.50em;
  right: 1.50em;
  z-index: 1;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: all 500ms ease;
  overflow: auto;
  scrollbar-width: thin;
}

.alert--messages::-webkit-scrollbar {
  background: var(--clr-blue);
  width: 4px;
}

.alert--messages::-webkit-scrollbar-thumb {
  background: var(--clr-white);
  border-radius: 15px;
}

.wrapper {
  height: 600px;
  width: 300px;
  background: #131413;
  border: 2px solid black;
  position: fixed;
  bottom: 1.50em;
  right: 1.50em;
  overflow: hidden;
}

.alertcontainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.alert2 {
  background: #131413;
  padding: 2em;
  height: 600px;
  width: 550px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  transition: all 500ms ease;
}

.alert2--form label {
  color: white;
  margin-top: 0.5em;
}

.alert2 form {
  color: white;
  display: flex;
  flex-direction: column;
}

.alertform--small input {
  display: flex;
  flex-direction: column;
  padding-top: -3em;
}

.alertform--small label {
  font-size: small;
}

.alert2--input {
  background: #DEDEDE;
  color: #000000;
  border: none;
  border-radius: 10px;
  width: 50%;
  margin: 0.5em auto;
  padding: 0.8em;
  font-family: "DM Sans";
  font-weight: 400;
  outline: none;
}

.alert2--notes {
  padding: 1em;
}

.alert2--deposit {
  background: #131413;
  height: 1000px;
  width: 550px;
  position: fixed;
  bottom: 13em;
  right: 60em;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: all 500ms ease;
}

.alert2--balance {
  background: #0E0E0E;
  color: var(--clr-white);
  display: flex;
  flex-direction: row;
  border: 5px solid var(--clr-blue);
  border-radius: 10px;
  width: 100%;
  margin: 0.75em;
  padding: 1.5em;
  font-weight: 700;
  cursor: pointer;
  position: relative;
  transition: all 250ms ease;
}

.alert2--balance:hover {
  color: var(--clr-white);
  border: 5px solid var(--clr-white);
}

.alert2--balance h1 {
  margin-left: 3em;
  margin-top: .3em;
}

.alert2--address {
  background-color: var(--clr-secondary);
  height: 55px;
  width: 500px;
  border-radius: 20px;
  margin: 1em;
}

.alert2--address h1 {
  margin-right: 12em;
  margin-bottom: 0.3em;
  font-size: large;
}

.alert2--address p {
  margin-right: 5em;
  color: var(--clr-neutral);
}

.alert2--content {
  margin: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 300px;
  width: 350px;
}

.alert2--content form {
  margin: 1em;
  display: flex;
  flex-direction: row;
}

.alert2 button {
  color: white;
  background-color: #ffa600;
  margin-top: 1em;
}

.alert2--content form button:hover {
  width: 80px;
  height: 30px;
  color: var(--clr-white);
  border: 2px solid var(--clr-white);
}

.alert2--content span::after {
  content: '';
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  right: 6px;
  transform: translate(50%, -50%);
  width: 10px;
  height: 12px;
  transition: all 250ms ease;
}

.alert2--content button:hover span::after {
  background-size: 7px;
  right: 12px;
  transition: all 200ms ease;
}

.alert2--content form img {
  margin-right: 5px;
  margin-left: -10px;
  width: 15px;
  height: 15px;
}

.alert2--content p img {
  width: 25px;
  height: 25px;
  transform: translateX(-10px) translateY(7px);
}

.close {
  height: 40px;
  width: 40px;
  position: absolute;
  top: 20px;
  left: 20px;
  transition: 600ms ease;
  cursor: pointer;
}

.alert2--warning {
  background: var(--clr-red);
  border-radius: 30px;
  width: 250px;
  margin: 10px;
  font-size: medium;
}

.alert2--warning--remove {
  opacity: 0;
  pointer-events: none;
}

.close:hover {
  transform: rotate(180deg);
  transition: 400ms ease;
}

.alert a {
  color: var(--clr-blue);
  text-decoration: none;
  margin: 0.25em;
}

.alert a:hover {
  color: var(--clr-white);
}

.alert--remove {
  opacity: 0;
  pointer-events: none;
}

.messagetable {
  margin-bottom: 1em;
}

.messageBody {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.messageLabel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: xx-large;
  z-index: 10;
  background: #222D41;
  border-bottom: 10px solid rgba(0, 0, 0, .5);
  border-top: 10px solid rgba(0, 0, 0, .5);
  position: fixed;
  top: 728px;
  right: 257px;
}

.messagetable2 {
  margin-bottom: 1em;
}

.messageBody2 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.messageLabel2 {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
  font-size: xx-large;
  z-index: 10;
  background: #222D41;
  border-bottom: 10px solid rgba(0, 0, 0, .5);
  border-top: 10px solid rgba(0, 0, 0, .5);
  position: fixed;
  top: 728px;
  right: 30px;
  min-width: 68px;
  align-items: center;
}

.messageform {
  position: fixed;
  max-width: 150px;
  max-height: 70px;
  bottom: 100px;
}

.messageTables {
  display: flex;
  flex-direction: row;
  margin-bottom: -20em;
}

.messageButton {
  background: #0E0E0E;
  color: var(--clr-blue);
  border: 5px solid var(--clr-blue);
  border-radius: 3px;
  width: 50%;
  height: 50%;
  padding: 1.15em;
  font-weight: bolder;
  font-family: "DM Sans";
  cursor: pointer;
  position: relative;
  transition: all 250ms ease;
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}

/* ------------------------------------------------------ */
/* -- About -- */

.about h2 {
  font-size: 42px;
  margin: 10px auto;
}

.about p {
  font-size: 18px;
  line-height: 130%;
  max-width: 75ch;
  width: 50%;
}

/* ------------------------------------------------------ */
/* -- Projects -- */

.projects h2 {
  font-size: 42px;
  margin: 25px auto;
}

.projects__main {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 100%;
}

.projects p {
  font-size: 20px;
  max-width: 50ch;
}

.projects__cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 50px;
}

.projects__card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 2em;
  width: 350px;
  height: 400px;
  border-radius: 25px;
  overflow: hidden;
  position: relative;
}

.projects__card img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 25px;
}

.projects__card:hover .projects__card-content {
  opacity: 1;
}

.projects__card-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  color: white;
  padding: 20px;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  transition: opacity 0.3s ease-in-out;
}

/* ------------------------------------------------------ */
/* -- Experience -- */

.experience h2 {
  font-size: 42px;
  margin: 25px auto;
}

.experience p {
  font-size: 20px;
  line-height: 150%;
  margin-bottom: 25px;
}

.experience__timeline {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}

.experience__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 20px 0;
  position: relative;
}

.experience__item::before {
  content: '';
  position: absolute;
  left: 50%;
  top: 0;
  height: 100%;
  width: 2px;
  background-color: #333;
  transform: translateX(-50%);
}

.experience__item:last-child::before {
  display: none;
}

.experience__item h3 {
  font-size: 22px;
  margin: 0;
}

.experience__item p {
  font-size: 18px;
  margin: 5px 0;
}

/* ------------------------------------------------------ */
/* -- Footer -- */

.footer {
  text-align: center;
  background-color: #222222;
  color: white;
  padding: 2em 0;
}

.footer p {
  font-size: 16px;
}

.footer a {
  color: #ffa600;
  text-decoration: none;
  font-weight: bold;
}

.footer a:hover {
  text-decoration: underline;
}

/* ------------------------------------------------------ */
/* -- Responsive Breakpoints -- */

/* Large Screens (desktops) */
@media only screen and (min-width: 1200px) {
  .projects__cards {
    flex-direction: row;
  }

  .header__content h1 {
    font-size: 75px;
  }

  .header__content p {
    font-size: 50px;
  }

  .buttonhead p {
    font-size: 35px;
  }

  .projects__card {
    max-width: 400px;
  }

  .buttonhead {
    width: 70%;
    height: 50%;
  }
}

/* Medium Screens (tablets) */
@media only screen and (max-width: 1199px) and (min-width: 768px) {
  .header__content h1 {
    font-size: 50px;
  }

  .header__content p {
    font-size: 40px;
  }

  .line {
    margin: 0 auto;
  }

  .projects__card {
    max-width: 45%;
  }

  .buttonhead {
    font-size: 13px;
    height: 50%;
    width: 70%;
  }

  .projects__card img {
    max-height: 250px;
  }

  .header__content {
    width: 100%;  /* Adjust width to fit smaller screens */
    margin: 0 auto;
    align-items: center;  /* Center horizontally */
    justify-content: center; /* Center vertically */
    padding: 0px; /* Optional padding for spacing */
  }
}

/* Small Screens (mobiles) */
@media only screen and (max-width: 767px) {
  .header__content h1 {
    font-size: 30px;
  }

  .header__content p {
    font-size: 25px;
  }

  .projects__cards {
    flex-direction: column;
    align-items: center;
  }

  .projects__card {
    width: 90%;
    max-width: 100%;
  }

  .buttonhead {
    width: 70%;
    height: 50%;
    font-size: 14px;
    padding: 15px;
  }

  .header__content button {
    width: 100px;
  }

  .header__content {
    width: 70%;  /* Adjust width to fit smaller screens */
    margin: 0 auto;
    align-items: center;  /* Center horizontally */
    justify-content: center; /* Center vertically */
    padding: 0px; /* Optional padding for spacing */
  }

  .line {
    margin: 0 auto;
  }
}

/* Extra Small Screens (very small devices) */
@media only screen and (max-width: 480px) {
  
  .header__content h1 {
    font-size: 24px;
  }

  .header__content p {
    font-size: 20px;
  }

  .projects__card {
    max-width: 90%;
    margin: 0.5em;
  }

  .buttonhead {
    font-size: 14px;
    padding: 15px;
    width: 70%;
    height: 30%;
  }

  .header__content {
    width: 100%;  /* Adjust width to fit smaller screens */
    height: 40%;
    margin: 0 auto;
    align-items: center;  /* Center horizontally */
    justify-content: center; /* Center vertically */
    padding: 0px; /* Optional padding for spacing */
  }

  .alert2{
    width: 350px;
  }
  
}
